<script setup lang="ts">
import dayjs from 'dayjs/esm'
import bottomSheet from '/~/core/bottom-sheet'
import ui from '/~/core/ui'
import { getNumberWithOrdinal } from '/~/utils/format/string'
import DaypickerDropdown from '/~/components/base/datepicker/daypicker/daypicker.dropdown.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'

const emit = defineEmits<{
  (event: 'select', value: dayjs.Dayjs | number): void
}>()

const props = withDefaults(
  defineProps<{
    value: number | null
    isDisabled?: boolean
    hideIcon?: boolean
    activePeriod?: {
      from: dayjs.Dayjs | null
      to: dayjs.Dayjs | null
    }
    hint?: string
  }>(),
  {
    isDisabled: false,
    hideIcon: false,
    activePeriod: () => {
      return {
        from: dayjs().date(0),
        to: dayjs().date(31),
      }
    },
    hint: '',
  }
)

const { translate } = useLocalization()

function showDaypickerBs() {
  if (ui.mobile) {
    bottomSheet.show('payment-date-picker', {
      props: {
        value: props.value,
        activePeriod: props.activePeriod,
        hint: props.hint,
        onConfirm: (day: number) => {
          emit('select', day)
        },
      },
    })
  }
}
</script>

<template>
  <div v-if="value">
    <span class="text-xl font-bold text-eonx-neutral-800">
      Select preferred date
    </span>
    <component
      :is="ui.mobile ? 'div' : DaypickerDropdown"
      v-bind="props"
      :selectable="true"
      :class="['my-4', isDisabled && 'pointer-events-none opacity-50']"
      @click="showDaypickerBs"
      @select="$emit('select', $event)"
    >
      <div
        class="flex h-11 cursor-pointer justify-between rounded border bg-white px-3 py-2.5 outline outline-2 outline-transparent transition-all duration-300 hover:outline-primary group-focus:outline-primary"
      >
        <div class="flex space-x-2.5">
          <base-icon
            v-if="!hideIcon"
            svg="heroicons/outline/calendar"
            class="w-6 text-eonx-neutral-600"
          />
          <span>{{ getNumberWithOrdinal(value) }} of each month</span>
        </div>
        <base-icon
          svg="heroicons/outline/chevron-down"
          class="ml-auto w-5 text-eonx-neutral-600 transition-all duration-300 group-focus:rotate-180"
        />
      </div>
    </component>
    <div
      class="inline-flex items-start justify-start gap-2 rounded bg-blue-50 p-1.5 text-blue-700"
    >
      <base-icon
        class="relative h-6 w-6"
        svg="heroicons/mini/information-circle"
      ></base-icon>
      <div class="flex shrink grow basis-0 items-start justify-start gap-4">
        <div class="shrink grow basis-0 text-sm font-normal leading-5">
          We may process your payment before or after your preferred date,
          subject to when we receive your monthly statement
          {{ translate('common.and') }} to allow enough time for payment
          processing.
        </div>
      </div>
    </div>
  </div>
</template>
